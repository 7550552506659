import Axios from 'axios'

const alphabetList = document.querySelector('.alphabet')
const form = document.querySelector('.form-wally')
const searchInput = document.querySelector('.wally-search')
const searchBtn = document.querySelector('.wally-btn-icon')

let items = []
let cards = []

function requestDefault() {
  Axios.get(`https://api.fatecrl.edu.br/api/v1/wally/docentes/a`)
    .then((response) => {
      const cardsList = document.querySelector('.wallyCards')
      addCard(response.data)
      cardsList.innerHTML = cards.join('')
    })
}

function getDataInInput(event) {
  event.preventDefault()
  const searchedText = searchInput.value.trim().toLowerCase().split(' ').join('+')

  Axios.get(`https://api.fatecrl.edu.br/api/v1/wally/docentes/${searchedText}`)
    .then((response) => generateResponse(response.data))
}

function requestBySubmit() {
  form.addEventListener('submit', (event) => getDataInInput(event), false)
}

function requestBySearchButton() {
  searchBtn.addEventListener('click', (event) => getDataInInput(event), false)
}

function requestByLetter() {
  alphabetList.addEventListener('click', (event) => {
    const item = event.target
    const letter = event.target.textContent.toLowerCase()

    Array.from(alphabetList.querySelectorAll('.alphabet-letter'))
      .map(letter => letter.classList.remove('letter--active'))

    if (item.tagName === 'LI') {
      item.classList.add('letter--active')
    }

    Axios.get(`https://api.fatecrl.edu.br/api/v1/wally/docentes/${letter}`)
      .then((response) => generateResponse(response.data))
  }, false)
}

function generateResponse(data) {
  cards = []
  const cardsList = document.querySelector('.wallyCards')

  if (data.length !== 0) {
    document.querySelector('.wallyCard-message-box').remove()
    addCard(data)
  } else {
    if (!document.querySelector('.wallyCard-message-box')) {
      alphabetList.insertAdjacentHTML('afterend', `<div class="wallyCard-message-box"><p class="wallyCard-message">Nenhum Professor(a) encontrado(a)!</p></div>`)
    }
  }

  cardsList.innerHTML = cards.join('')
}

function addLetter(letter) {
  return items.push(`<li class="alphabet-letter">${letter}</li>`);
}

function generatePhotoName(name) {
  return name.toLowerCase().split(' ').join('-')
}

function addCard(data) {
  data.map(card => cards.push(`
    <div class="wallyCard">
      <img class="wallyCard-photo" src="http://localhost/images/docentes/${generatePhotoName(card.nome)}.jpg">
      <span class="wallyCard-nick">${card.apelido}</span>
      <h2 class="wallyCard-name">${card.nome}</h2>
      <span class="wallyCard-schedule">ver horários</span>
    </div>
  `))
}

export function generateAlphabet(element) {
  if (element) {
    const alphabet = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
    alphabet.map(letter => addLetter(letter))
    element.innerHTML = items.join('')

    element.querySelector('.alphabet-letter').classList.add('letter--active')
  }
}

export function wally() {
  generateAlphabet(alphabetList)
  requestDefault()
  requestByLetter()
  requestBySearchButton()
}
