export function startAccordion(accordion) {
  accordion && accordion.addEventListener('click', (event) => {
    const topic = event.target

    Array.from(accordion.querySelectorAll('.accordion-question'))
      .map((panel) => panel.classList.remove('question--active'))

    if (topic.tagName === 'SPAN')
      topic.classList.add('question--active')

    Array.from(accordion.querySelectorAll('.accordion-panel'))
      .map((panel) => panel.classList.remove('panel--show'))

    const panel = topic.nextElementSibling
    panel.classList.add('panel--show')
  })
}
