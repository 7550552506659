import { marathonAPI } from './marathon'
import { graduationAPI } from './graduation'

export function events(url) {
  if (url.split('/')[2] === 'maratonas-de-programacao') {
    marathonAPI(url)
  }
  //  else if (url.split('/')[2] === 'formaturas') {
  //   graduationAPI(url)
  // }
}
