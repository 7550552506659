import './index.sass'

import { router } from './scripts/requests/routes';
import { startTabs } from './scripts/components/tabs'
import { startSlider } from './scripts/components/banner'
import { startAccordion } from './scripts/components/accordion'
import { startVestibular} from './scripts/components/vestibular'
import { startInternalMenu } from './scripts/components/internal-menu'
import { menuScroll, toggleMenu } from './scripts/header'

const $$ = window
const $ = document

startVestibular(true, $.querySelector('.accordions'))

startTabs($.querySelector('.page-tabs'))

startSlider({
  slides: $.querySelectorAll('.manager'),
  controls: $.querySelectorAll('.control')
})

startAccordion($.querySelector('.accordions'))

startInternalMenu({
  menu: $.querySelector('.page-menuList'),
  button: $.querySelector('.btn--change')
})

menuScroll($.querySelector('.auxiliaryBar'))

toggleMenu({
  menu: $.querySelector('.mainBar-menu'),
  button: $.querySelector('.mobileMenu')
})

router($$.location.pathname)
