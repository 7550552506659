
const { title, content, button } = {
  title: 'Vestibular',
  text: 'Abertas as <b>inscrições (15/08 à 20/09)</b> para o Vestibular Fatec 2º Semestre de 2018. Abertas as inscrições (15/08 à 20/09) para.',
  button: '+ Informações',
}

function vestibular(title, content, button) {
  return `
    <section class="vestibular">
      <div class="container">
        <div class="vestibularBody">
          <h2 class="vestibularBody-title">${title}</h2>
          <p class="vestibularBody-text">${content}</p>
          <button class="vestibularBody-button">
              <a href="" class="vestibularBody-link">${button}</a>
          </button>
        </div>
      </div>
    </section>
  `
}

export function startVestibular(status, node) {
  if (status === true)
    node && node.insertAdjacentHTML('afterbegin', vestibular(title, content, button))
}
