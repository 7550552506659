export function menuScroll(bar) {
  if (bar) {
    window.addEventListener('scroll', (event) => {
      if (window.scrollY > bar.scrollHeight) bar.classList.add('hide')
      else bar.classList.remove('hide')
    })
  }
}

export function toggleMenu(data) {
  data.button.addEventListener('click', (event) => {
    data.button.classList.toggle('button--active')
    data.menu.classList.toggle('menu--show')
    loginsInMobile(data.menu)
  })
}

function loginsInMobile(menu) {
  menu.querySelector('.menu').insertAdjacentHTML('beforeend', logins())
}

function logins() {
  return `
    <li class="menu-item" data-id="logins-mobile">
      <div class="menu-item-box">
        <a class="menu-link" href="http://outlook.com/fatec.sp.gov.br">E-mail - Fatec</a>
      </div>
    </li>
    <li class="menu-item" data-id="logins-mobile">
      <div class="menu-item-box">
        <a class="menu-link" href="http://fatecrledu.kinghost.net/moodle/">Moodle</a>
      </div>
    </li>
    <li class="menu-item" data-id="logins-mobile">
      <div class="menu-item-box">
        <a class="menu-link" href="https://siga.cps.sp.gov.br/aluno/login.aspx">Siga</a>
      </div>
    </li>
  `
}
