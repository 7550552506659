
let index = 0

function disableManager(managers) {
  Array.from(managers).filter((item) => item.classList.remove('manager--active'))
}

function disableControls(controls) {
  Array.from(controls).filter((item) => item.classList.remove('control--active'))
}

function switchManager(data) {
  disableManager(data.slides)
  disableControls(data.controls)

  data.slides[index].classList.add('manager--active')
  data.controls[index].classList.add('control--active')

  index++

  setTimeout(() => {
    if (index === data.slides.length) index = 0
    switchManager(data)
  }, 4000)
}

export function startSlider(data) {
  return (data.slides.length > 1) ? switchManager(data) : false
}
