const doc = document

const contents = Array.from(doc.querySelectorAll('.page-tab-content'))
const sidebars = Array.from(doc.querySelectorAll('.page-tab-manager'))

function disableContents() {
  contents.map((content) => content.classList.remove('page-tab-content--active'))
}

function disableSidebars() {
  sidebars.map((sidebar) => sidebar.classList.remove('page-tab-manager--active'))
}

function disableLinks(links) {
  links.map((link) => link.classList.remove('page-tab--active'))
}

export function startTabs(element) {
  const links = Array.from(doc.querySelectorAll('.page-tab'))

  element && element.addEventListener('click', (event) => {
    const link = event.target

    links.forEach((item, index) => {
      if (item.tagName === 'LI' && link === item) {
        disableLinks(links)
        disableContents()
        disableSidebars()

        item.classList.add('page-tab--active')
        contents[index].classList.add('page-tab-content--active')
        sidebars[index].classList.add('page-tab-manager--active')
      }
    })
  })
}
