import { events } from './events/events'
import { wally } from './wally'

export function router(url) {
  if (url.split('/')[1] === 'eventos') {
    events(url)
  } else if (url.split('/')[1] === 'wally') {
    wally()
  }
}
