import Axios from 'axios'
import { formateDate } from '../../formatter'

const doc = document

const data = {
  photos: doc.querySelector('[data-id=marathon-edition-photos]'),
  selectBox: doc.querySelector('[data-id=marathon-editions]'),
  rankings: doc.querySelectorAll('[data-id=ranking]'),
  responsable: doc.querySelector('[data-id=marathon-responsable]'),
  date: doc.querySelector('[data-id=marathon-date]'),
  training: doc.querySelector('[data-id=marathon-training]'),
  tournament: doc.querySelector('[data-id=marathon-tournament]')
}

function generateEditions(box, amount) {
  const options = []

  for (let index = amount; index > 0; index--)
    options.push(`<option class="marathon-edition" value="${index}">${index}ª edição</option>`)

  box.innerHTML = options.join('')
}

function generateInformations(marathonDate, marathonSchedule, marathonResponsable) {
  data.date.textContent = formateDate(marathonDate)
  data.responsable.textContent = `${marathonResponsable.principal}, ${marathonResponsable.auxiliar}`
  data.training.textContent = `${marathonSchedule.aquecimento}`
  data.tournament.textContent = `${marathonSchedule.competicao}`
}

function generateRanking(names) {
  data.rankings[0].textContent = names.primeiro
  data.rankings[1].textContent = names.segundo
  data.rankings[2].textContent = names.terceiro
}

function generateEditionPhotos(link) {
  data.photos.setAttribute('href', link)
}

function latestEdition() {
  Axios.get(`https://api.fatecrl.edu.br/api/v1/maratonas-de-programacao/mais-recente`)
    .then((response) => {
      generateEditions(data.selectBox, response.data.qtd)
      generateEditionPhotos(response.data.fotos)
      generateRanking(response.data.vencedores.lugares)
      generateInformations(response.data.competicao.data, response.data.competicao.horarios, response.data.competicao.responsaveis)
    }, false)
}

function getEdition(box) {
  box && box.addEventListener('change', (event) => {
    Axios.get(`https://api.fatecrl.edu.br/api/v1/maratonas-de-programacao/edicoes/${box.value}`)
      .then((response) => {
        generateEditionPhotos(response.data.fotos)
        generateRanking(response.data.vencedores.lugares)
        generateInformations(response.data.competicao.data, response.data.competicao.horarios, response.data.competicao.responsaveis)
      })
  })
}

export function marathonAPI(url) {
  if (url.split('/')[3] === undefined)
    latestEdition()

  getEdition(data.selectBox)
}
